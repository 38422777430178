@font-face {
  font-family: 'PilcrowRounded';
  src: url('assets/fonts/PilcrowRounded-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'PilcrowRounded';
  src: url('assets/fonts/PilcrowRounded-Variable.ttf') format('truetype');
  font-style: normal;
  font-weight: lighter;
}
@font-face {
  font-family: 'PilcrowRounded';
  src: url('assets/fonts/PilcrowRounded-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'PilcrowRounded';
  src: url('assets/fonts/PilcrowRounded-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'PilcrowRounded';
  src: url('assets/fonts/PilcrowRounded-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}
